@use '@angular/material' as mat;

@mixin asset-profile-container-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .asset-profile-container {
    .back-to-previous {
      color: mat.get-color-from-palette($foreground, hyperlink);
      @include mat.typography-level($zonar-default-typography, body-1);
      font-size: 0.9rem;
      cursor: pointer;
      line-height: 20px;
      vertical-align: middle;
    }

    .asset-profile-content-container {
      min-width: 0;

      .card-container {
        
        // widget themes to be included below this line

        // Example:
        // &.my-widget  { ... }

        &.asset-properties-widget {
          .key-value-row-key {
            font-weight: 600;
          }
        }

        &.asset-utilization-histogram-widget {
          min-height: 21.875rem;
        }

        &.widget-2 {
          min-height: 4rem;
        }

        &.widget-3 {
          min-height: 15rem;
        }

        &.widget-5 {
          min-height: 12rem;
        }

        &.widget-6 {
          min-height: 17.175rem;
        }
        // widget themes to be included above this line

        &.hidden {
          display: none;
        }
      }

      .feed-title {
        font-size: 1.8rem;
        color: mat.get-color-from-palette($foreground, base);
        margin-bottom: 10px;
      }
    }
  }

}
