@use '@angular/material' as mat;

@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme';
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';
@import '@zonar-ui/maintenance/zonar-ui-maintenance.theme';
@import '@zonar-ui/activity-feed/zonar-ui-activity-feed.theme';
@import '@zonar-ui/asset-usage/zonar-ui-asset-usage.theme';
@import '@zonar-ui/utilization/zonar-ui-utilization.theme';

@import '../app/components/home-page/home-page.theme';
@import '../app/containers/asset-profile-container/asset-profile-container.component.theme';
@import '../app/components/asset-header/asset-header.theme';
@import '../app/components/card/card.theme';

@mixin apply-zonar-theme($theme) {
  $primary: map-get($theme, primary);

  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);
  @include zonar-ui-maintenance-theme($theme);
  @include zonar-ui-activity-feed-theme($theme);
  @include zonar-ui-asset-usage-theme($theme);
  @include zonar-ui-utilization-theme($theme);

  @include asset-profile-container-theme($theme);
  @include home-page-theme($theme);
  @include asset-profile-header-theme($theme);
  @include card-theme($theme);

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  zui-utilization-histogram {
    .chart-title {
      padding-right: 40%;
    }
  }
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: black;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}

::-webkit-scrollbar {
  width: 0.313rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
  background: $silver;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $silver;
}

::-webkit-scrollbar-track {
  background: $conti-6;
}
