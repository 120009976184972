@use '@angular/material' as mat;

@mixin card-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  mat-card.card-container {
    margin-bottom: 1rem;
  }

}
