@use "@angular/material" as mat;

@mixin home-page-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-home-page {
    .page-content {
      .title {
        @include mat.typography-level($zonar-default-typography, display-1);
      }

      .message {
        @include mat.typography-level($zonar-default-typography, body-1);
      }

      .home-page-btn {
        @include mat.typography-level($zonar-default-typography, body-1);
      }
    }
  }
}
