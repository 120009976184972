@use 'node_modules/@angular/material' as mat;

@mixin asset-profile-header-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .asset-header-container {
    .asset-header-name-status {
      .asset-name {
        color: mat.get-color-from-palette($foreground, text);
      }

      .asset-status {
        .lozenge {
          @include mat.typography-level($zonar-default-typography, caption);
          font-weight: 700;
          text-transform: uppercase;
          border-radius: .25em;
          padding: 0.25em 0.5em;
          width: fit-content;

          &.general {
            background-color: mat.get-color-from-palette($foreground, icon-steel);
            color: mat.get-color-from-palette($foreground, text-flat-button);
          }

          &.success {
            background-color: mat.get-color-from-palette($foreground, icon-pass);
            color: mat.get-color-from-palette($foreground, text-flat-button);
          }

        }
      }
    }

    .asset-year-make-model {
      color: mat.get-color-from-palette($foreground, text);
    }
  }
}
